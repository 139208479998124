<template>
  <div class="dataExport">
    <Header
      title="数据导出"
      index="首页"
      titleOne="数据管理"
      beforeTitle="数据导出"
    />
    <div class="content">
      <div class="export">
        <!-- 数据导出类型下拉菜单 -->
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item>
            <el-select v-model="form.type" placeholder="请选择数据导出类型">
              <el-option label="合作客户" value="partner"></el-option>
              <el-option label="意向客户" value="potential"></el-option>
              <el-option label="专利列表" value="patent"></el-option>
              <el-option label="软著列表" value="soft"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <!-- 去导出按钮 -->
        <el-row>
          <el-button type="primary" @click="go_export()">去导出</el-button>
        </el-row>
      </div>
      <h1>文件导出说明</h1>
      <span class="data_text"
        >数据可导出类型：合作客户、意向客户、专利列表、软著列表。</span
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        type: "",
      },
    };
  },
  methods: {
    go_export() {
      if (this.form.type) {
        this.$router.push({
          path: "/data/data_export/go_export",
          query: { item: this.form.type },
        });
        console.log(this.form.type);
      } else {
        this.$message({
          type: "error",
          message: "请选择需要导出的类型",
        });
      }
    },
  },
};
</script>

<style scoped>
.export {
  display: flex;
  /* align-items: center; */
  border-bottom: 1px solid #edeef4;
}
div/deep/.el-form-item {
  width: 224px;
  margin-right: 15px;
}
div/deep/.el-form-item__content {
  margin-left: 0 !important;
}
.data_text {
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  color: #000000;
}
</style>
